/* global zaraz */
import React from 'react';
import ReactDOM from 'react-dom';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import Loader from '@PROJECT_ROOT/assets/responsive-frontend/js/parts/loader';
import HoneypotField from '@PROJECT_ROOT/assets/responsive-frontend/js/formFields/honeypotField';
import generateState from '@PROJECT_ROOT/assets/responsive-frontend/js/services/generateStateService';
import formInputValidator from '@PROJECT_ROOT/assets/responsive-frontend/js/services/formInputValidator';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import TextField from '@PROJECT_ROOT/assets/responsive-frontend/js/formFields/textField';
import { ToastContainer, toast } from 'react-toastify';

export default class NewsletterFooterForm extends React.Component {
    constructor(props) {
        super(props);
        this.pathExtension = 'newsletter/subscribe/';
        this.postPath = 'newsletter/subscribe-to';
        this.redirectTime = 10000;
        this.formElementRef = React.createRef();
        this.langISO = document.documentElement.dataset.adriaticlang;
        this.state = {
            publicResources: null,
            errorValidationMsg: null,
            isLoading: null,
            isSubmit: false,
            isFormValid: false,
            isPostLoading: null,
        };
        this.getData = this.getData.bind(this);
        this.postData = this.postData.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePostData = this.handlePostData.bind(this);
        this.constructRequestBody = this.constructRequestBody.bind(this);
        this.handleFeedbackErrors = this.handleFeedbackErrors.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    handleInputChange(e) {
        e.persist();
        this.setState((prev) => ({
            formData: {
                ...prev.formData,
                [e.target.name]: e.target.value,
            },
        }));
    }

    handleSubmitForm(e) {
        e.preventDefault();
        const { publicResources, formData } = this.state;
        this.setState({ isSubmit: true });
        if (formInputValidator.isRequiredFieldsFilled(formData, publicResources.form.formElements)) {
            this.setState({ isFormValid: true }, () => this.handlePostData());
        }
    }

    handlePostData() {
        const stateData = this.state;
        if (stateData.isFormValid && stateData.isSubmit) {
            this.postData(this.constructRequestBody());
        }
    }

    handleFeedbackErrors() {
        const { errorValidationMsg } = this.state;
        if (this.formElementRef.current) {
            formInputValidator.displayValidationErrorMessages(errorValidationMsg.validationErrorMessages, this.formElementRef.current);
        }
    }

    getData() {
        const stateData = this.state;
        if (!stateData.publicResources) {
            this.setState({ isLoading: true });
            serviceData
                .getData(this.pathExtension)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            publicResources: res.data,
                            formData: generateState.formStateGenerator(res.data.form.formElements),
                        });
                        return;
                    }
                    reportService.reportError(res);
                })
                .catch((err) => {
                    reportService.reportError(err);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    postData(bodyData) {
        this.setState({ isPostLoading: true });
        serviceData
            .postApiData(this.postPath, bodyData)
            .then((res) => {
                formInputValidator.removeValidationMessages(this.formElementRef.current);
                this.setState({
                    errorValidationMsg: null,
                });
                if (res.status === 200) {
                    if (res.data.validationErrorMessages) {
                        this.setState({
                            errorValidationMsg: res.data,
                            isFormValid: false,
                        }, () => { this.handleFeedbackErrors(); });
                    }
                    if (res.data.status === 'failure') {
                        toast.error(res.data.message?.title);
                    } else if (res.data.status === 'success') {
                        toast.success(res.data.message?.title);
                        if (typeof zaraz !== 'undefined' && zaraz.consent) {
                            const marketing = zaraz.consent.get('KlIi');
                            if (zaraz.track && marketing) {
                                zaraz.track('FooterNewsletterSubscribeSubmit');
                            }
                        }
                    }
                    return;
                }
                reportService.reportError(res);
            })
            .catch((err) => {
                this.setState({
                    isFormValid: false,
                });
                reportService.reportError(err);
            })
            .finally(() => {
                this.setState({
                    isPostLoading: false,
                });
            });
    }

    constructRequestBody() {
        const { formData } = this.state;
        const requestBody = {
            twoLetterISOLanguageCode: this.langISO,
            ...formData,
        };
        return requestBody;
    }

    render() {
        const stateData = this.state;
        const { publicResources } = this.state;
        return (
            <>
                <ToastContainer
                    autoClose={3000}
                    theme="colored"
                    position="bottom-right"
                />
                {stateData.isLoading && <Loader />}
                {publicResources && (
                    <form
                        className="form__wrapper newsletterFooterForm"
                        ref={this.formElementRef}
                        onSubmit={this.handleSubmitForm}
                    >
                        <h4 className="newsletterTitle">{publicResources.mainTitle}</h4>
                        <div className="newsletterFooterFormWrap">
                            {publicResources.form.formElements.map((field, index) => {
                                switch (field.type) {
                                    case 'emailField':
                                        return (
                                            <div className="form__article" key={index}>
                                                <TextField
                                                    fieldData={field}
                                                    currentStateValue={stateData.formData[field.name]}
                                                    controlStateData={{ isSubmit: stateData.isSubmit }}
                                                    onChangeHandler={this.handleInputChange}
                                                    inputType="email"
                                                />
                                            </div>
                                        );
                                    case 'honeypotField':
                                        return (
                                            <HoneypotField
                                                key={field.type + index}
                                                fieldData={field}
                                                currentStateValue={stateData.formData[field.name]}
                                                onChangeHandler={this.handleInputChange}
                                                controlStateData={{ isSubmit: stateData.isSubmit }}
                                            />
                                        );
                                    default:
                                        return null;
                                }
                            })}
                            <button
                                className="form__button form__button--primary"
                                type="submit"
                                disabled={stateData.isPostLoading}
                            >
                                {publicResources.form.submitAction.title}
                            </button>
                        </div>
                    </form>
                )}
            </>
        );
    }
}

const newsletterFooterForm = document.getElementById('newsletterFooterReact');
ReactDOM.render(<NewsletterFooterForm />, newsletterFooterForm);
