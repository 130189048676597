import React, { useState, useRef, useEffect, useMemo } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { MdErrorOutline } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import commonEmailDomains from 'email-providers/common.json';

export default function TextField({
    fieldData,
    currentStateValue,
    onChangeHandler,
    controlStateData,
    disabledField = false,
    showAdditionalError = false,
    inputType,
    addInstance,
    deleteInstance,
    placeholderText,
    selectedCountryCode,
    showErrorsOnDirty = false,
}) {
    const inputRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [lastSelectedDialCode, setLastSelectedDialCode] = useState(null);
    const [currentEmailUsername, setCurrentEmailUsername] = useState(null);
    const [currentEmailDomain, setCurrentEmailDomain] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [tempDisabledField, setTempDisabledField] = useState(false);
    const [tempStateValue, setTempStateValue] = useState(null);
    const fieldDataId = fieldData.id ?? fieldData.name;
    const isEmptyState = !currentStateValue || currentStateValue.trim() === '';
    const isFormSubmit = controlStateData && controlStateData.isSubmit;
    const additionalText = fieldData.additionalText ?? fieldData.helpText ?? fieldData.commentText ?? null;
    const isInputTypePassword = inputType === 'password' || false;
    const passwordTypeResult = !showPassword ? 'password' : 'text';
    const isRequiredError = fieldData.isRequired && isEmptyState;
    const isAdditionalError = fieldData.additionalErrorMessage && showAdditionalError;
    const isAnyError = isRequiredError || isAdditionalError;
    const showErrorMessages = isFormSubmit || (showErrorsOnDirty && isDirty);

    const suggestedEmailDomains = useMemo(() => {
        if (!currentEmailDomain) return [];
        return commonEmailDomains
            .filter((emailDomain) => {
                if (currentEmailDomain.length > 1) {
                    return emailDomain.includes(currentEmailDomain);
                }
                return emailDomain.startsWith(currentEmailDomain);
            })
            .slice(0, 5);
    }, [currentEmailDomain]);

    const handlePasswordShow = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const getErrorMessage = () => {
        if (!showErrorMessages) { return null; }
        if (isRequiredError) { return fieldData.requiredError; }
        if (isAdditionalError) { return fieldData.additionalErrorMessage; }
        return null;
    };

    const setInputValue = (value) => {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value',
        ).set;
        nativeInputValueSetter.call(inputRef.current, value);
        const event = new Event('input', { bubbles: true });
        inputRef.current.dispatchEvent(event);
    };

    const getCountryDialCode = () => {
        setTempDisabledField(true);
        serviceData
            .getDataWithoutLangISO(`country-calling-code/${selectedCountryCode}`)
            .then((res) => {
                if (res.status === 200) {
                    const newDialCode = res.data.countryCallingCode;
                    if (isEmptyState || (lastSelectedDialCode && currentStateValue === lastSelectedDialCode) || (tempStateValue && tempStateValue === lastSelectedDialCode)) {
                        if (!isDirty) {
                            setTempStateValue(newDialCode);
                        } else {
                            setInputValue(newDialCode);
                        }
                    }
                    setLastSelectedDialCode(newDialCode);
                    return;
                }
                reportService.reportError(res);
            })
            .catch((err) => {
                reportService.reportError(err);
            })
            .finally(() => {
                setTempDisabledField(false);
            });
    };

    useEffect(() => {
        if (!selectedCountryCode || selectedCountryCode === ' ') { return; }

        getCountryDialCode();
        // TODO provjerit moze li se drugacije izbjec warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountryCode]);

    useEffect(() => {
        if (inputType !== 'email') { return; }

        const atIndex = currentStateValue.lastIndexOf('@');
        if (atIndex === -1) {
            setCurrentEmailUsername(null);
            setCurrentEmailDomain(null);
            return;
        }

        setCurrentEmailUsername(currentStateValue.slice(0, atIndex + 1));
        setCurrentEmailDomain(currentStateValue.slice(atIndex + 1));
    }, [currentStateValue, inputType]);

    useEffect(() => {
        if (!controlStateData.isSubmit) {
            setIsDirty(false);
        }
    }, [controlStateData.isSubmit]);

    const handleClear = () => {
        setInputValue('');
        setTempStateValue(null);
        setLastSelectedDialCode(null);
    };

    const handleChange = (e) => {
        setIsDirty(true);
        setTempStateValue(null);
        onChangeHandler(e);
    };

    return (
        <>
            <div id={`${fieldDataId}_wrapper`} className={`form__input--wrapper ${isAnyError && showErrorMessages ? 'form__input--wrapper-error' : ''} ${!isEmptyState || tempStateValue ? 'form__input--wrapper-filled' : ''}`}>
                <input
                    ref={inputRef}
                    className={`form__input ${isInputTypePassword ? 'form__input--padding' : ''} ${inputType === 'email' ? 'form__input--email' : ''}`}
                    id={fieldDataId}
                    name={fieldData.name}
                    value={!isDirty && tempStateValue ? tempStateValue : currentStateValue}
                    onChange={handleChange}
                    type={isInputTypePassword ? passwordTypeResult : (inputType || 'text')}
                    maxLength={fieldData.maxLength || 200}
                    disabled={disabledField || tempDisabledField}
                    placeholder={placeholderText || fieldData.placeholderText}
                    list={inputType === 'email' ? 'emailDomains' : null}
                    onBlur={() => inputType === 'email' && setInputValue(currentStateValue?.trim())}
                />
                <label
                    className={`form__label ${disabledField ? 'form__label--disabled' : ''}`}
                    htmlFor={fieldDataId}
                >
                    <span className="form__labelTitle">
                        {fieldData.title}{fieldData.isRequired && <span className="form__asterisk--required"> *</span>}
                    </span>
                </label>
                {inputType === 'email' && (
                    <datalist id="emailDomains">
                        {currentEmailUsername && currentEmailDomain && suggestedEmailDomains.map((emailDomain, index) => (
                            <option key={index} value={`${currentEmailUsername}${emailDomain}`}>{currentEmailUsername}{emailDomain}</option>
                        ))}
                    </datalist>
                )}
                {!disabledField && (!isEmptyState || tempStateValue) && !isInputTypePassword && (
                    <button type="button" className="form__input--clearButton" aria-label="Clear text" onClick={handleClear} disabled={tempDisabledField} tabIndex={-1}>
                        <IoMdCloseCircleOutline className="form__input--clearIcon" />
                    </button>
                )}
                {!disabledField && !isInputTypePassword && <MdErrorOutline className="form__input--errorIcon m-0 customClas" />}
                {!disabledField && isInputTypePassword && (
                    <button type="button" className="form__input--togglePasswordModeIcons" onClick={handlePasswordShow} aria-label="Toggle password mode" tabIndex={-1}>
                        {showPassword ? (
                            <BsEyeSlash className="form__input--togglePasswordIcon" />
                        ) : (
                            <BsEye className="form__input--togglePasswordIcon" />
                        )}
                    </button>
                )}
            </div>
            {!disabledField && addInstance && (
                <button className="addInputButton" type="button" onClick={() => addInstance()}>
                    +
                </button>
            )}
            {!disabledField && deleteInstance && (
                <button className="addInputButton" type="button" onClick={() => deleteInstance()}>
                    -
                </button>
            )}
            {additionalText && (!isAnyError || !showErrorMessages) && (
                <span className="form__message form__message--additional form__message--visible">
                    {additionalText}
                </span>
            )}
            <span className={`form__message form__message--error ${isAnyError && showErrorMessages ? 'form__message--visible' : ''}`}>
                {getErrorMessage() ?? (additionalText ? '' : <>&nbsp;</>)}
            </span>
        </>
    );
}
