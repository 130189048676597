export default class generateState {
    static formStateGenerator(arrayElements) {
        if (!Array.isArray(arrayElements)) {
            return {};
        }
        return arrayElements.reduce((state, field) => {
            const { name, type, defaultValue, defaultOptionValues, isChecked } = field;
            if (name) {
                switch (type) {
                    case 'numberBox':
                        state[name] = defaultValue || 0;
                        break;
                    case 'checkBox':
                        state[name] = isChecked || defaultValue || false;
                        break;
                    case 'multipleCheckBox':
                        state[name] = defaultValue || [];
                        break;
                    case 'fileField':
                        state[name] = defaultValue || field.maxNumberOfDocuments > 1 ? [] : null;
                        break;
                    case 'selectBox':
                        // TODO Ukloniti defaultValue ako za sve selectBox-ove bude defaultOptionValues array(ali tek
                        // nakon brisanja svih dummy podataka).
                        state[name] = defaultOptionValues ? defaultOptionValues[0] : defaultValue || '';
                        break;
                    default:
                        state[name] = defaultValue || '';
                        break;
                }
            }
            return state;
        }, {});
    }

    static emptyStateGenerator(stateObject) {
        const stateEntries = Object.entries(stateObject);
        const createdState = {};
        stateEntries.forEach(([key, value]) => {
            switch (typeof value) {
                case 'string':
                    createdState[key] = '';
                    break;
                case 'boolean':
                    createdState[key] = false;
                    break;
                case 'number':
                    createdState[key] = 0;
                    break;
                case 'object':
                    if (Array.isArray(value)) {
                        createdState[key] = [];
                    } else {
                        createdState[key] = null;
                    }
                    break;
                default:
                    createdState[key] = null;
                    break;
            }
        });
        return createdState;
    }

    static removeEmptyStates(stateObject) {
        if (stateObject) {
            return Object.fromEntries(
                Object.entries(stateObject)
                    .filter(([, value]) => {
                        if (Array.isArray(value)) {
                            return value.length > 0;
                        }
                        return value !== '' && value !== false;
                    }),
            );
        }
        return {};
    }

    static compareStates(currentState, lastSuccessState) {
        const currentStateString = JSON.stringify(currentState);
        const lastSuccessStateString = JSON.stringify(lastSuccessState);
        return currentStateString === lastSuccessStateString;
    }
}
