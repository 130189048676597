import React from 'react';

export default function HoneypotField({
    fieldData,
    currentStateValue,
    onChangeHandler,
}) {
    return (
        <input
            autoComplete="off"
            tabIndex={-1}
            className="form__inputHoneypot"
            id={fieldData.id ?? fieldData.name}
            name={fieldData.name}
            value={currentStateValue}
            onChange={onChangeHandler}
            type="text"
            maxLength={fieldData.maxLength || 200}
            aria-hidden="true"
        />
    );
}
