export default class formInputValidator {
    // ID prefix za validacijske poruke, koristi se na dva mjesta i mora biti identičan, zbog toga je definiran posebno.
    static idPrefixForErrorSpan = 'spanValidationIdMessage_';

    static errorMessageValidator(name, value, state) {
        let error = '';
        switch (name) {
            case 'text':
                if (value.trim() === '') {
                    error = 'This field is required';
                }
                break;
            case 'email':
                if (!value.match(/^\S+@\S+\.\S+$/)) {
                    error = 'Invalid Email';
                }
                if (value === '') {
                    error = '';
                }
                break;
            case 'password_check':
                if (value !== state.password) {
                    error = 'Passwords do not match';
                }
                break;
            default:
                break;
        }
        return error;
    }

    static isRequiredFieldsFilled(stateData, formElements, skipFieldsValidation = []) {
        if (!stateData || !formElements || formElements.length === 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        }

        const emptyRequiredField = formElements.find((el) => {
            if (el.isRequired && el.name && !skipFieldsValidation?.includes(el.name)) {
                const currentState = stateData[el.name];
                if (!currentState || currentState === null || currentState === undefined) {
                    return true;
                }
                if ((Array.isArray(currentState) && currentState.length === 0) || currentState[0] === null) {
                    return true;
                }
                if (typeof currentState === 'number' && currentState <= 0) {
                    return true;
                }
                if (typeof currentState === 'string' && currentState.trim() === '') {
                    return true;
                }
                if (el.name === 'password_check' && currentState !== stateData.password) {
                    return true;
                }
            }
            return false;
        });

        if (emptyRequiredField) {
            const emptyFieldName = emptyRequiredField.name;
            if (emptyFieldName && document.getElementById(emptyFieldName)) {
                document.getElementById(emptyFieldName).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest' });
                document.getElementById(emptyFieldName).focus();
            }
            return false;
        }
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        // Ovo sam zakomentirao jer na naslovnici nam ne treba, potrebno je prilagoditi logiku.
        return true;
    }

    static displayValidationErrorMessages(validationErrors, elementRef) {
        this.removeValidationMessages(elementRef);
        if (validationErrors.length > 0) {
            let firstErrorFieldElement;
            validationErrors.forEach((validationError) => {
                const errorFieldId = validationError.formElementId ?? validationError.formElementName;
                const errorFieldElement = elementRef?.querySelector(`[id="${errorFieldId}"]`) ?? document.getElementById(errorFieldId);
                if (!errorFieldElement) return;
                const errorFieldParentElement = errorFieldElement.parentNode;
                if (!firstErrorFieldElement) {
                    firstErrorFieldElement = errorFieldElement;
                }
                let errorMsgSpan = document.getElementById(this.idPrefixForErrorSpan + errorFieldId);
                if (!errorMsgSpan) {
                    errorMsgSpan = document.createElement('span');
                    errorMsgSpan.id = this.idPrefixForErrorSpan + errorFieldId;
                    errorMsgSpan.className = 'form__message form__message--error form__message--visible';
                    const existingErrorMsgSpan = errorFieldParentElement.parentNode.querySelector('.form__message--error');
                    errorFieldParentElement.parentNode.insertBefore(errorMsgSpan, existingErrorMsgSpan);
                }
                errorMsgSpan.textContent = validationError.validationMessage;
                errorFieldParentElement.classList.add('form__input--wrapper-error');
            });
            if (firstErrorFieldElement) {
                firstErrorFieldElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest' });
                firstErrorFieldElement.focus();
            }
        }
    }

    static removeValidationMessagesFor(inputElement) {
        if (!inputElement) { return; }

        const inputParentElement = inputElement.parentNode;
        const errorMessageElements = inputParentElement.parentNode.querySelectorAll(`[id^="${this.idPrefixForErrorSpan}"]`);
        errorMessageElements.forEach((element) => {
            element.remove();
        });
    }

    static removeValidationMessages(elementRef) {
        let spans;
        let inputs;
        if (elementRef && elementRef.querySelectorAll(`[id^="${this.idPrefixForErrorSpan}"]`)) {
            spans = elementRef.querySelectorAll(`[id^="${this.idPrefixForErrorSpan}"]`);
            inputs = elementRef.querySelectorAll('.form__input--wrapper-error');
        } else {
            spans = document.querySelectorAll(`[id^="${this.idPrefixForErrorSpan}"]`);
            inputs = document.querySelectorAll('.form__input--wrapper-error');
        }
        spans.forEach((span) => {
            if (span.parentNode && span.parentNode.contains(span)) {
                span.parentNode.removeChild(span);
            }
        });
        inputs.forEach((input) => {
            input.classList.remove('form__input--wrapper-error');
        });
    }
}
